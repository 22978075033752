.container {
  display: flex;
  padding: 0;
  margin: auto 0 0;
}

.disabledIcon {
  justify-content: flex-end;
  width: 20px;
  padding-right: 3px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.stakeContainer {
  display: flex;
  position: relative;
  list-style-type: none;

  &:first-child {
    width: 160px;
    clip-path: polygon(12px 0, 100% 0, calc(100% - 8px) 100%, 0% 100%);

    & button {
      padding-left: 20px;
      padding-right: 16px;
    }

    & .disabledIcon {
      width: 22px;
    }
  }

  &:nth-child(2) {
    width: 160px;
    clip-path: polygon(8px 0, 100% 0, calc(100% - 8px) 100%, 0% 100%);

    & button {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &:last-child {
    width: 168px;
    clip-path: polygon(8px 0, calc(100% - 27px) 0, 100% 100%, 0% 100%);

    & button {
      padding-left: 16px;
      padding-right: 30px;
    }
  }

  & :global(.three-bets) {
    & button {
      &:first-of-type,
      &:last-of-type {
        padding-right: 81px;
      }

      &:nth-child(2) {
        width: 73px;
        padding-left: 5px;
      }
    }
  }
}

.stake {
  width: 100%;
  border: none;
  border-radius: 0;
  z-index: 1;

  & button {
    background-color: var(--static-gray-120);

    & span {
      &:first-child {
        color: var(--static-gray-40);
      }

      &:last-child {
        color: var(--static-black);
      }
    }

    &:first-child {
      border-color: var(--static-gray-100);
    }

    &:hover {
      background-color: var(--static-gray-100);

      & span:first-child {
        color: var(--static-black);
      }
    }

    &:global(.active) {
      background-color: var(--static-secondary);
    }

    &:first-of-type {
      padding-top: 10px;
      padding-bottom: 6px;
    }

    &:last-of-type {
      padding-top: 6px;
      padding-bottom: 11px;
    }

    &:nth-child(2) {
      border-radius: 0;
      border-color: var(--static-gray-100);
    }
  }
}
