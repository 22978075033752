:global(.carousel-root) {
  width: 100%;
  height: 100%;
}

:global(.carousel) {
  border-radius: 8px;

  & :global(.control-dots) {
    display: flex;
    justify-content: center;

    margin: 0;
    padding-top: 3px;

    & :global(.dot) {
      box-shadow: none;
      background-color: transparent;
      width: unset;
      height: unset;
      padding: 6px;
      margin: 0;
      opacity: 1;
      transition: background-color .25s ease-in;

      &::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--static-white);
        box-shadow: var(--shadow-1);
      }
    }

    & :global(.dot:hover::before) {
      opacity: .65;
    }

    & :global(.dot.selected) {
      cursor: default;

      &::before {
        background-color: transparent;
        border: 1px solid var(--static-white);
      }
    }
  }

  & img {
    width: unset;
  }
}

:global(.carousel .slide) {
  text-align: left;

  & img {
    width: unset;
  }
}
